import React from "react";
import styled from "styled-components";
// Assets
import {faPlaneDeparture} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp, faInstagram, faFacebook} from "@fortawesome/free-brands-svg-icons";

import AddImage2 from "../../assets/img/pexels-leah-kelley-618902.jpg";
import FullButton from "../Buttons/FullButton";
export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightblueBg">
        <div className="container" style={{ marginLeft: "5%", width: "auto"}}>
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <h4 className="font15 semiBold whiteColor">Es hora de hacer tu sueno realidad</h4>
              <h2 className="font40 extraBold whiteColor">Contactanos</h2>
              <p className="font15 whiteColor">
                Haz tu sueño de visitar Estados Unidos una realidad. ¡Contáctanos hoy mismo!
                Estamos aquí para allanar el camino hacia tu próxima aventura. ¡Tu visa de turista está a solo un mensaje de distancia!
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "60px", marginLeft: "15px"}}>
                  <a href="https://wa.me/50671286925">
                    <FontAwesomeIcon icon={faWhatsapp} fontSize="50px" style={{color: "#ffffff",}} />
                  </a>
                </div>
                <div style={{ width: "60px", marginLeft: "15px" }}>
                  <a href="https://www.instagram.com/davisa.cr">
                    <FontAwesomeIcon icon={faInstagram} fontSize="50px" style={{color: "#ffffff",}} />
                  </a>
                </div>
                <div style={{ width: "60px", marginLeft: "15px" }}>
                  <a href="https://www.instagram.com/davisa.cr">
                    <FontAwesomeIcon icon={faFacebook} fontSize="50px" style={{color: "#ffffff",}} />
                  </a>
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight>
              <Icon>
                <FontAwesomeIcon icon={faPlaneDeparture} fontSize="200px" style={{color: "#ffffff",}} />
              </Icon>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 60px 0 60px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
  }
`;

const Icon = styled.div`
  margin-left: 20%;
  @media (max-width: 860px) {
    margin-left: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;



