import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilRuler, faUmbrella, faPassport, faHourglassHalf} from "@fortawesome/free-solid-svg-icons";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Tarifas</h1>
            <p className="font15">
              Nos adaptamos a tus necesidades, por eso te ofrecemos diferentes planes para que puedas elegir el que mejor se adapte a ti.
              <br />
              Consulta por nuestros paquetes especiales para grupos.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                  icon={<FontAwesomeIcon icon={faPassport} fontSize="50px" style={{color: "#67ced4",}} />}
                  price="$40 p/p"
                title="Sin miedo al exito"
                offers={[
                  { name: "Asesoria Personalizada", cheked: true },
                  { name: "Simulacro de Entrevista", cheked: true },
                  { name: "Programar Cita En La Embajada", cheked: true },
                  { name: "Completar Formulario de Visa", cheked: true },
                  { name: "Reagendar Cita", cheked: false }, 
                    { name: "Garantia En Caso de Perder La Visa", cheked: false },
                    { name: "Renovacion de Visa De Por Vida", cheked: false }, 
                    { name: "Programa de Fidelidad", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                  icon={<FontAwesomeIcon icon={faUmbrella} fontSize="50px" style={{color: "#67ced4",}} />}
                  price="$60 p/p"
                title="Por si las dudas"
                offers={[
                    { name: "Asesoria Personalizada", cheked: true },
                    { name: "Simulacro de Entrevista", cheked: true },
                    { name: "Programar Cita En La Embajada", cheked: true },
                    { name: "Completar Formulario de Visa", cheked: true },
                    { name: "Reagendar Cita", cheked: true },
                    { name: "Garantia En Caso de Perder La Visa", cheked: true },
                    { name: "Renovacion de Visa De Por Vida", cheked: false },
                    { name: "Programa de Fidelidad", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                  icon={<FontAwesomeIcon icon={faHourglassHalf} fontSize="50px" style={{color: "#67ced4",}} />}
                  price="$80 p/p"
                title="De por vida"
                offers={[
                    { name: "Asesoria Personalizada", cheked: true },
                    { name: "Simulacro de Entrevista", cheked: true },
                    { name: "Programar Cita En La Embajada", cheked: true },
                    { name: "Completar Formulario de Visa", cheked: true },
                    { name: "Reagendar Cita", cheked: true },
                    { name: "Garantia En Caso de Perder La Visa", cheked: true },
                    { name: "Renovacion de Visa De Por Vida", cheked: true },
                    { name: "Programa de Fidelidad", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




