import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import AddImage1 from "../../assets/img/pexels-pixabay-64271.jpg";
import AddImage2 from "../../assets/img/pexels-leah-kelley-618902.jpg";
import AddImage3 from "../../assets/img/pexels-vlada-karpovich-4449619.jpg";
import AddImage4 from "../../assets/img/pexels-josh-hild-2422588.jpg";
import {Link} from "react-scroll";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Con nosotros puedes ir donde quieras</h1>
          <HeaderP className="font15 semiBold">
            En Davisa, nos dedicamos a ser el puente que conecta tus suenos con la realidad. Nuestra misión es facilitar el proceso de gestion de visa americana, guiándote paso a paso para que alcances tus metas de conocer Estados Unidos.
          </HeaderP>
          <BtnWrapper>
            <FullButton title={
              <Link className="whiteColor animate pointer font13" to="services" smooth={true} offset={-80}>
                Comenzar Ahora!
              </Link>
            } />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <div className="flexNullCenter">
          <AddImgWrapp1 className="flexCenter">
            <img src={AddImage1} alt="office" />
          </AddImgWrapp1>
          <AddImgWrapp2>
            <img src={AddImage2} alt="office" />
          </AddImgWrapp2>
        </div>
        <div className="flexNullCenter">
          <AddImgWrapp3>
            <img src={AddImage3} alt="office" />
          </AddImgWrapp3>
          <AddImgWrapp4>
            <img src={AddImage4} alt="office" />
          </AddImgWrapp4>
        </div>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 10px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;


